@font-face {
    font-family: 'GT-Walsheim';
    src: url('../fonts/GT-Walsheim-Regular.eot') format('embedded-opentype'),
        url('../fonts/GT-Walsheim-Regular.woff') format('woff'),
        url('../fonts/GT-Walsheim-Regular.woff2') format('woff2'),
        url('../fonts/GT-Walsheim-Regular.ttf') format('truetype'),
        url('../fonts/GT-Walsheim-Regular.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'GT-Walsheim';
    src: url('../fonts/GT-Walsheim-Bold.eot') format('embedded-opentype'),
        url('../fonts/GT-Walsheim-Bold.woff') format('woff'),
        url('../fonts/GT-Walsheim-Bold.woff2') format('woff2'),
        url('../fonts/GT-Walsheim-Bold.ttf') format('truetype'),
        url('../fonts/GT-Walsheim-Bold.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}
